export function validateCPF (cpf: string): boolean  {
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

    const calcCheckDigit = (base: string, factor: number): number => {
      let total = 0;
      for (let i = 0; i < base.length; i++) {
        total += parseInt(base[i]) * (factor - i);
      }
      return (total * 10) % 11 % 10;
    };

    const base = cpf.slice(0, 9);
    const digit1 = calcCheckDigit(base, 10);
    const digit2 = calcCheckDigit(base + digit1, 11);

    return digit1 === parseInt(cpf[9]) && digit2 === parseInt(cpf[10]);
};