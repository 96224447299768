import React, { useState, useEffect } from 'react';
import './UpdateUsersPage.css';
import { Button, Text, Grid, Col, Title, TextInput, Flex, Card } from "@tremor/react";
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { TransporterServiceArea } from '../../interfaces/AccessInterfaces';
import SidebarComponent from '../../components/SidebarComponent';
import TitleComponent from "../../components/TitleComponent";
import { TrashIcon } from '@heroicons/react/24/outline';

export default function UpdateTransporterServiceAreaPage() {
  const [cookies] = useCookies(['mazelog-user']);
  const token = cookies['mazelog-user']?.token_data?.access_token;
  const oauth = "Bearer " + token;
  const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL;

  let { id } = useParams();

  const [requestProcessing, setRequestProcessing] = useState(false);
  const [transporterServiceArea, setTransporterServiceArea] = useState<TransporterServiceArea>();

  const navigate = useNavigate();
  const getTransporterData = () => {
    axios.get(`${baseURL}/transporters/service_areas?id_org=${id}`,
        {headers: {
          "Content-Type": "application/json",
          "Authorization": oauth,
        }})
    .then((res) => {
        setTransporterServiceArea(res.data[0])
    })
    .catch((err)=>{
        alert('Erro!');
        navigate('/access')
    });
  }

  const updateServiceAreas = () => {
    setRequestProcessing(true);
    axios.put(`${baseURL}/transporters/service_areas`,
        transporterServiceArea,
      {headers: {
        "Content-Type": "application/json",
        "Authorization": oauth,
      }})
    .then((res) => {
      alert("CEPs da transportadora atualizados com sucesso!");
      navigate('/access')
    })
    .catch((err)=>{
      alert(`Erro! ${err.response?.data?.detail}`);
      setRequestProcessing(false);
    });
  }

  const addNewServiceAreaRow = () => {
    if (!!transporterServiceArea) {
        setTransporterServiceArea({
            ...transporterServiceArea,
            service_zip_ranges: [
                ...transporterServiceArea?.service_zip_ranges,
                ["00000-000", "00000-000"]]
        })
    }
  }

  const removeServiceAreaRow = (rowIndex:number) => {
    if (!!transporterServiceArea) {
        setTransporterServiceArea({
            ...transporterServiceArea,
            service_zip_ranges: transporterServiceArea?.service_zip_ranges.filter((_, i)=> i !== rowIndex)
        })
    }
  }

  const updateServiceAreaRow = (rowIndex:number, newValue:string[]) => {
    if (!!transporterServiceArea) {
        setTransporterServiceArea({
            ...transporterServiceArea,
            service_zip_ranges: transporterServiceArea.service_zip_ranges.map(
                (value, idx) => (idx === rowIndex) ? newValue : value
            )
        })
    }
  }

  useEffect(()=>{
    getTransporterData();
  },[])

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <div className="CreateUserViews">
            <Card>
                <Title>Atualizar CEPs de operação</Title>
                {!transporterServiceArea ? "" :
                <Grid numCols={2} className="gap-x-0 gap-y-1">
                    <Col numColSpan={1}>
                        <Title>ID Organização</Title>
                        <Text>{transporterServiceArea.id_org.toString()}</Text>
                    </Col>
                    <Col numColSpan={1}>
                        <Title>Nome da transporatdora</Title>
                        <Text>{transporterServiceArea.transporter_name}</Text>
                    </Col>
                    
                    <Col numColSpan={2}>
                    <Title>CEPs de operação</Title>
                    { transporterServiceArea.service_zip_ranges
                    .map((zip_range, index) => (
                        <Flex justifyContent='start'>
                            <TextInput
                                className='max-w-xs'
                                onChange={(e) => {updateServiceAreaRow(index, [e.target.value, zip_range[1]])}}
                                value={zip_range[0]}
                            />
                            <TextInput
                                className='max-w-xs'
                                onChange={(e) => {updateServiceAreaRow(index, [zip_range[0], e.target.value])}}
                                value={zip_range[1]}
                                />
                            <Button
                                onClick={() => {removeServiceAreaRow(index)}}
                                className='OpenOrderBtn'
                                icon={TrashIcon}
                            />
                        </Flex>
                    )) }
                    </Col>
                    
                </Grid>}
            <div className='max-w-md'>
                <Button
                onClick={addNewServiceAreaRow}
                className='FilterButton'
                > Adicionar nova linha </Button>
                <Button
                loading={requestProcessing}
                onClick={updateServiceAreas}
                className='OpenStickerButton'
                > Atualizar </Button>
            </div>
            </Card>
        </div>
      </div>
    </main>
  );
}