import React, { useState, useEffect } from 'react';
import './UpdateUsersPage.css';
import { Button, Text, Grid, Col, Title, TextInput, Dropdown, DropdownItem, Card } from "@tremor/react";
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { User } from '../../interfaces/AccessInterfaces';
import SidebarComponent from '../../components/SidebarComponent';
import TitleComponent from "../../components/TitleComponent";
import { validateCPF } from '../../utils/validaCpf';

export default function UpdateUsersPage() {
  const [cookies] = useCookies(['mazelog-user']);
  const token = cookies['mazelog-user']?.token_data?.access_token;
  const oauth = "Bearer " + token;
  const baseURL = process.env.REACT_APP_MAZELOG_AUTH_BASE_URL;

  let { id } = useParams();

  const [requestProcessing, setRequestProcessing] = useState(false);
  const [user, setUser] = useState<User>();
  const [cpfError, setCpfError] = useState<string | null>(null);

  const navigate = useNavigate();
  const getUserData = () => {
    axios.get(`${baseURL}/users/${id}`,
        {headers: {
          "Content-Type": "application/json",
          "Authorization": oauth,
        }})
    .then((res) => {
        setUser(res.data)
    })
    .catch((err)=>{
        alert('Erro!');
        navigate('/access')
    });
  }

  const updateUser = () => {

    // Limpar formatação do telefone antes de enviar
    const userClean = { ...user };
    userClean.phone = userClean.phone?.replace(/[^\d]/g, ''); // Remove formatação do telefone

        
    setRequestProcessing(true);
    axios.put(`${baseURL}/users/${id}`,
      userClean,
      {headers: {
        "Content-Type": "application/json",
        "Authorization": oauth,
      }})
    .then((res) => {
      alert("Usuário atualizado com sucesso!");
      navigate('/access')
    })
    .catch((err)=>{
      alert('Erro!');
      setRequestProcessing(false);
    });
  }

  useEffect(()=>{
    getUserData();
  },[]) 

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneValue = e.target.value.replace(/\D/g, ''); // Remove tudo que não for número
    let maskedPhone = '';

    if (phoneValue.length > 10) {
      // Com código de país (exemplo: 55 11 96467-7742)
      maskedPhone = phoneValue.replace(/^(\d{2})(\d{2})(\d{4,5})(\d{4})$/, '($1) $2 $3-$4');
    } else {
      // Sem código de país (exemplo: 11 96467-7742)
      maskedPhone = phoneValue.replace(/^(\d{2})(\d{4,5})(\d{4})$/, '($1) $2-$3');
    }

    setUser({ ...user!, phone: maskedPhone });
  };

  const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cpf = e.target.value.replace(/\D/g, '');
    setUser({ ...user!, cpf });
    setCpfError(validateCPF(cpf) ? null : 'CPF inválido.');
  };

  return (
    <main className="Page">
      <SidebarComponent />
      <div className="Content">
        <TitleComponent />
        <div className="CreateUserViews">
            <Card>
                <Title>Atualizar usuário</Title>
                {!user ? "" :
                <Grid numCols={1} className="gap-2">
                    <Col numColSpan={1}>
                        <Text>ID Organização</Text>
                        <TextInput 
                        className='max-w-md'
                        onChange={(e) => {setUser({...user, id_org: parseInt(e.target.value)})}}
                        defaultValue={user.id_org.toString()}
                        />
                    </Col>
                    <Col numColSpan={1}>
                      <Text>Nome de usuário</Text>
                      <TextInput
                        className='max-w-md'
                        onChange={(e) => { setUser({ ...user, username: e.target.value }); }}
                        defaultValue={user.username}
                      />
                    </Col>
                    <Col numColSpan={1}>
                      <Text>Nome completo</Text>
                      <TextInput
                        className='max-w-md'
                        onChange={(e) => { setUser({ ...user, full_name: e.target.value }); }}
                        defaultValue={user.full_name}
                      />
                    </Col>
                    <Col numColSpan={1}>
                      <Text>Email</Text>
                      <TextInput
                        className='max-w-md'
                        onChange={(e) => { setUser({ ...user, email: e.target.value }); }}
                        defaultValue={user.email}
                      />
                    </Col>
                    <Col numColSpan={1}>
                      <Text>Senha (caso queira manter a mesma, deixar em branco)</Text>
                      <TextInput
                        className='max-w-md'
                        onChange={(e) => { setUser({ ...user, password: e.target.value }); }}
                      />
                    </Col>
                    <Col numColSpan={1}>
                      <Text>CPF</Text>
                      <TextInput
                        className='max-w-md'
                        value={user.cpf}
                        onChange={handleCpfChange}
                      />
                      {cpfError && <Text className="text-red-500">{cpfError}</Text>}
                    </Col>
                    <Col numColSpan={1}>
                      <Text>Telefone</Text>
                      <TextInput
                        className='max-w-md'
                        value={user.phone}
                        onChange={handlePhoneChange}
                      />
                    </Col>
                    <Col>
                        <Text>Perfil</Text>
                        <Dropdown
                        className='max-w-md'
                        defaultValue={user.user_profile}
                        onValueChange={ (value) => {setUser({...user,user_profile:value})} }
                        >
                        <DropdownItem value="ADMIN" text="ADMIN" />
                        <DropdownItem value="EMPLOYEE" text="EMPLOYEE" />
                        <DropdownItem value="CLIENT" text="CLIENT" />
                        <DropdownItem value="TRANSPORTER" text="TRANSPORTER" />
                        <DropdownItem value="TRANSPORTER_COLLECTER" text="TRANSPORTER_COLLECTER" />
                        </Dropdown>
                    </Col>
                </Grid>}
            <Button
            disabled={requestProcessing}
            onClick={updateUser}
            className='CreateUserBtn'
            > Atualizar </Button>
            </Card>
        </div>
      </div>
    </main>
  );
}